/* eslint-disable react/display-name */
import { memo, useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import Link from 'next/link';

import { useRouter } from 'next/router';

import { HHero, H2, Text1Bold, Caption, A } from 'src/components/Text';
import ErrorNotification from 'src/components/ErrorNotification';
import { RoutePath } from 'src/constants/route-path';

import { getCookie, setCookie } from 'src/utils/cookie';

import { StepProps } from '../../models';

import {
  StepBellytypeWrapper,
  StepBellytypeChoices,
  StepBellytypeChoiceItem,
  BellytypeImg,
  BellytypeBtn,
  PrivacyWrapper,
  PrivacyCheckbox,
  EmailConfirmWrapper,
  EmailConfirmCheckbox,
} from './step-bellytype-styled';

export const StepBellytype = memo(
  ({ data, onSubmit, showEmailCheckbox }: StepProps): JSX.Element => {
    const router = useRouter();
    const { t } = useTranslation('quiz');

    const [privacyChecked, togglePrivacy] = useState(true);
    const [emailChecked, toggleEmail] = useState(true);

    // const [isUtm, setIsUtm] = useState(false);
    // const [isPP, setIsPP] = useState(false);

    useEffect(() => {
      setCookie('wantReceiveMarketingEmails', emailChecked ? '1' : '0');
    }, [emailChecked]);

    useEffect(() => {
      if (Object.values(router.query).length <= 2) {
        toggleEmail(false);
        togglePrivacy(false);
      }
    }, []);

    return (
      <StepBellytypeWrapper>
        <HHero>{data.title}</HHero>
        <H2>{data.title}</H2>
        <Text1Bold>{data.subtitle}</Text1Bold>
        <StepBellytypeChoices>
          {data.nav.map((nav): JSX.Element[] =>
            data.selects[nav.id].options.map(
              (option): JSX.Element => (
                <StepBellytypeChoiceItem
                  key={option.id}
                  onClick={
                    privacyChecked
                      ? (): void =>
                          onSubmit({ formValue: { [option.id]: option.value } })
                      : undefined
                  }
                >
                  <BellytypeImg>
                    <img src={option.icon} alt={option.title} loading="lazy" />
                  </BellytypeImg>
                  <BellytypeBtn disabled={!privacyChecked} type="button">
                    <Text1Bold>{option.title}</Text1Bold>
                  </BellytypeBtn>
                </StepBellytypeChoiceItem>
              )
            )
          )}
        </StepBellytypeChoices>
        <PrivacyWrapper>
          <PrivacyCheckbox
            className={privacyChecked ? 'checked' : ''}
            role="button"
            onClick={(): void => {
              togglePrivacy(!privacyChecked);
            }}
          />
          <Caption>
            {t('goal.agreement')}{' '}
            <Link
              href={`${RoutePath.termsOfService}`}
              passHref
              prefetch={false}
            >
              <A>{t('goal.terms')}</A>
            </Link>
            ,{' '}
            <Link href={`${RoutePath.privacyPolicy}`} passHref prefetch={false}>
              <A>{t('goal.privacy')}</A>
            </Link>
            ,{' '}
            <Link href={`${RoutePath.refundPolicy}`} passHref prefetch={false}>
              <A>{t('goal.moneyback')}</A>
            </Link>
            ,{' '}
            <Link
              href={`${RoutePath.subscriptionTerms}`}
              passHref
              prefetch={false}
            >
              <A>{t('goal.subscription')}</A>
            </Link>
            {', '}
            <Link href={`${RoutePath.cookiePolicy}`} passHref prefetch={false}>
              <A>{t('goal.cookiePolicy')}</A>
            </Link>
          </Caption>
        </PrivacyWrapper>
        {showEmailCheckbox && (
          <EmailConfirmWrapper>
            <EmailConfirmCheckbox
              className={emailChecked ? 'checked' : ''}
              role="button"
              onClick={(): void => {
                toggleEmail(!emailChecked);
              }}
            />
            <Caption>
              {t('goal.email')}
              {process.env.NEXT_PUBLIC_MIRROR_TITLE || 'Unimeal'}{' '}
              {t('goal.emailEnd')}
            </Caption>
          </EmailConfirmWrapper>
        )}
        {!privacyChecked && (
          <ErrorNotification>{t('goal.error')}</ErrorNotification>
        )}
      </StepBellytypeWrapper>
    );
  }
);

export default StepBellytype;
